

#root{
  height: 100%;
	display: flex;
	flex-direction: column;
  --grey: #707070;
  --darker-grey: #585858;
  --medium-grey: #F0F0F0;
  --light-grey: #EFEFEF;
  --light-blue: #7EB5D2;
  --primary: #0BB5A6;
  --secondary: #21A7FB;
  --success: #0BB5A6!important;
  --success-tint: #D9F4F2;
  --green: #0BB5A6!important;
  --info: #21A7FB;
  --warning: #FB8B21;
  --warning-tint: #FEDCBC;
  --yellow: #FB8B21;
  --danger: #DD3333;
  --danger-tint: #dd333333;
  --error: #DD3333;
  --red: #DD3333;
  --primary: #00D0BD;
  --bs-primary: #285949;
  --green: #00D0BD;
  --green-tint: #CCF6F2;
  --green-light: #E9F8F7;
  --green-dark: #033934;

}
html{
}
html,body{
  -webkit-font-smoothing: antialiased
}
p{
  word-break: break-all;
}
.min-h-100{
  min-height: 100%;
}
.sidebar .company-picker-container{
  width: calc(100% - 10px);
}
.main-logo{
  max-width: 100%;
}
.screenheight{
  /*100vh - meny - paddings*/
  height: calc(100vh - 76px - 2rem);
  max-height: calc(100vh - 76px - 2rem);

}
.btn{
  border-radius: .35rem;
  padding: .5rem 1.5rem;
  font-weight: 600;
}
@media (max-width: 767px){
  .btn{
    padding: .25rem .75rem
  }
}
/* SAGA KL OVERRIDES */
.btn-primary, .btn-success{
  background-color: var(--success);
  border-color: var(--success)
}
.btn-primary:hover, .btn-success:hover{
  background-color: var(--success-tint)!important;
  color: var(--success)!important;
  border-color: var(--success)
}
.table-row:hover{
  background-color: var(--secondary-tint)!important;
}
.table-row.pointer:hover,
.subtask-item.pointer:hover{
  background-color: var(--success-tint)!important;
}
.text-warning{
  color: #FB8B21!important;
}
.text-success{
  color: #435363!important;
}
.border-transparent{
  border-color: transparent!important;
}
.border-success{
  border-color: var(--green)!important;
}
.channel-sidebar-item .channel-sidebar-item:first-of-type{
  margin-top: .25rem;
}
.channel-sidebar-item:hover{
  background-color: rgba(0,0,0,.05);
}
.bg-login{
  background: transparent linear-gradient(137deg, #5DEDE0 -10%, #3BB9AE 15%, #1AA094 90%, #1B8D83 100%) 0% 0% no-repeat padding-box!important;
  z-index: -1;
}
.loginbox{
  max-width: 100%;
  width: 510px;
}
.coverimg{
  object-fit: cover;
  object-position: center;
}
.multiply{
  mix-blend-mode: multiply;
}
nav  ul > div > li.active > div, nav  ul > li.active{
  background-color: var(--green-tint)!important;
  border-color: var(--green)!important;
}
nav > ul > li.active a{
  color: var(--green)!important;
}

.sub-nav .active{
  color: var(--success)!important;
}

/* END */
.btn-primary.disabled, .btn-primary:disabled {
  opacity: .5;
  background-color: var(--primary);
  border-color: var(--primary);
}
.caps{
  text-transform: uppercase;
}

.btn-group-sm>.btn, .btn-sm{
  padding: .1rem .75rem
}
.btn-dashed{
  border: 1px dashed var(--grey);
  border-radius: 20px;
  color: var(--grey);
  margin-bottom: 1rem;
}
.text-primary{
  color: var(--primary)!important;
}
.text-grey{
  color: var(--grey)
}
.bg-primary{
  background-color: var(--primary);
}
.bg-grey{
  background-color: var(--light-grey)!important;
}
.channel-body .bg-grey{
  background-color: rgba(112,112,112,.09)!important;
}
a.link{
  color: var(--primary)
}
.btn-link{
  text-decoration: none;
}
.sidebar{
  background: #FFF;
}
.fade-enter {
  transform: translateX(100px);
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 250ms ease;
}
.fade-enter.fade-enter-done{
  position: relative;
}
.fade-appear{

}
.fade-appear-active{

}
.fade-exit{
  opacity: 1;
  transform: translateX(0);
  opacity: 1;
  z-index: 1;
}
.fade-exit-active{
  top:1.5rem;
  left:1.5rem;
  right:0;
  position: absolute;
  transform: translateX(-100px);
  opacity: 0;
  transition: all 250ms ease;
}
/** RESETS **/
.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}
.pointer{
  cursor: pointer;
}
a{
  color: inherit;
  text-decoration: none;
}
a:hover{
  color: inherit;
  text-decoration: none;
}
.btn-link{
  padding:0;
  color:inherit;
  font-weight: 600;
}
.btn-link.focus, .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
.btn-link:hover{
  color:inherit;
}
.hint-text{
  color:#AAADB3;
  font-weight: 500;
}

.medium{
  font-weight: 500;
}
.semibold{
  font-weight: 600
}
.bold{
  font-weight: 700;
}
.form-group label{
  color: #000!important;
}
.round{
  border-radius: 2rem!important;
}
.main{
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F5F6F7;
}
.row.medium-gutters{
  margin-right: -10px;
  margin-left: -10px;
}

.row.medium-gutters [class*="col"]{
  padding-right: 10px;
  padding-left: 10px;
}
.row.small-gutters{
    margin-right: -5px;
    margin-left: -5px;
}

.row.small-gutters [class*="col"]{
    padding-right: 5px;
    padding-left: 5px;
}
.main-content{
  flex-basis: 0;
  flex-grow: 1;
  max-height: 100%;
  padding: 0rem;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
.main-content::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
.main-content::-webkit-scrollbar-track {
	background: rgba(0,0,0,.1);
}

/* Handle */
.main-content::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
}

/* Handle on hover */
.main-content::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,.1);
}
.col.nav {
  flex: 0 0 20%;
  max-width: 350px;
}
@media (max-width: 767px){
  .col.nav{
    position: fixed;
    flex: 0 0 50%;
    max-width: 90%;
    top:0;
    left:0;
    bottom: 0;
    z-index: 100;
    transform: translateX(-100%);
    transition: all 250ms ease;
  }
  .col.nav.nav-open{
    transform: translateX(0);
  }
  
}

/** HEADER **/
.header{
  border-left: 1px solid var(--light-grey);
  position: relative;
}
.profile-image{
  height: 58px;
  width: 58px;
  object-fit: cover;
  border-radius: 50%;
}

.btn-header{
  flex: 0 0 auto;
  width: auto;
  border-color: transparent;
  border-left: 1px solid var(--light-grey);
  padding: 1.5rem;
  background-color: transparent;
  transition: all 250ms ease;
  color: var(--grey)
  
}
.btn-header:hover{
  background-color: var(--success-tint);
  color: var(--success);
}
@media (max-width: 767px){
  .btn-header{
    padding: 1rem;
  }
}

/** LOGO **/
.cls-1{fill:#3b4753;}.cls-2{fill:#00d0bd;}
.sidebar-logo{
  width: 100%;
}

/** NAV **/

nav.sidebar{
  width: 100%;
}
nav ul{
  list-style: none;
  padding: 0 10px;
  margin: 0;
  width: 100%;
}
nav ul li:not(.active) a:hover{
  background: rgba(0,0,0,.05)
}
nav ul li > div:not(.collapse) a{
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  font-weight: 600;
  margin-bottom: .2rem;
}
nav ul li.active a{
  color: #00D0BD
}
nav ul li .left-icon{
  margin-right: 10px;
  transition: transform 250ms ease;
}
nav ul li .rotate-icon.left-icon{
  transform: rotate(90deg)
}

nav ul li a, nav ul li button{
  flex-basis: 0;
  flex-grow: 1;
  text-align:left!important;
}
nav ul ul{
  padding-left: 2rem
}
nav > ul > div > li > div, nav > ul > li{
  position: relative;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: all 250ms ease
}

nav > ul > div > li.active > div, nav > ul > li.active{
  background-color: #CCF6F2;
  border-color: #00D0BD;
}
nav > ul > div > li.active > div, nav > ul > li.active{

}

nav ul ul li a{
  font-weight: 300;
  font-size: 1rem;
}
nav ul ul li{
  position: relative;
}
nav ul ul li:before{
  content:"";
  position:absolute;
  height: 100%;
  left: 1px;
  width: 1px;
  background-color: grey;
}
nav ul ul li.active:before{
  left:0;
  width: 3px;
  background-color: #00D0BD;
}
nav ul ul li.active a{
  font-weight: 600
}

.border{
  border: 1px solid #70707063;
  border-radius: 4px;
}

.border-lg{
  border: 1px solid #70707063;
  border-radius: 15px;
}

[class^='hover-'], [class*=' hover-']{
  transition: all 250ms ease ;
}

.hover-primary:hover{
  background-color: var(--success-tint);
  border-bottom: none!important;
}

.hover-lightgrey:hover{
  background-color: var(--light-grey);
}

nav hr{
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.notification-badge{
  background-color: var(--danger);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color:#FFF;
  position: absolute;
  transform: translate(15px, -12px);
}
.notification-bubble{
  transition: background 250ms ease;
}
.notification-bubble.notification-open{
  background-color: #F5F6F8;
}
.notification-bubble{
  position: relative;
}
.sidebar label{
  text-transform: uppercase;
  font-size: 0.8rem;
}
.notification-bubble span{
  position: absolute;
  color: #FFF;
  left: 50%;
  top:50%;
  transform:translate(-50%, -50%);
  font-size: .7rem;
}

.context-picker-notification-bubble{
  position: absolute;
  background-color: var(--danger);
  top:-5px;
  right:-5px;
  width: auto;
  color:#FFF;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.company-item {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
}

.submenu{
  border-bottom: 4px #F5F6F7 solid;
}
.submenu a{
  color: #656565;
  font-weight: 500;
  margin-right: 1rem;
  padding-bottom: 10px;
  margin-bottom: -4px
  
}
.submenu a.active{
  color:#000;
  border-bottom: 4px #000 solid;
  font-weight: 700;
}
.sub-nav-container{
  border-bottom: 1px solid rgba(112,112,112,.25);
  margin-bottom: 1rem;
} 
.sub-nav{
  margin-left: 0;
  padding-left: 0;
}
.sub-nav li{
  display: inline-block;
  padding-right: 1rem;
  margin-right: 1rem;
}
.sub-nav li{
  border-right: 1px solid rgba(112,112,112,.25);
}
.sub-nav li:last-of-type{
  border-right: none;
}
.sub-nav li span{
  cursor: pointer;
}
.sub-nav .active{
  color: #0BB5A6;
  font-weight: 600;
}
.company-item > *:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
.company-item > *:nth-child(2) { grid-area: 1 / 2 / 2 / 6; }
.company-item > *:nth-child(3) { grid-area: 1 / 6 / 2 / 8; }
.company-item > *:nth-child(4) { grid-area: 1 / 8 / 2 / 11; }
.company-item > *:nth-child(5) { grid-area: 1 / 11 / 2 / 15; }
.company-item > *:nth-child(6) { grid-area: 1 / 15 / 2 / 16; }

/** INPUT **/
input{
  border: 0;
  background-color: transparent;
}
input::placeholder{
  color: #979698
}
.input svg{
  color:#979698;
}
.input{
  border-radius: 8px;
  background-color:#F5F6F8;
  display: inline-block;
  padding: 1rem;
}
.react-code-input{
  display: flex!important;
  justify-content: center;
}
.react-code-input input{
  border-radius: 5px;
  box-shadow: none;
  padding: .1rem;
  border: 1px solid #ced4da;
  border-width: 2px;
  background: #FFF;
  color: #212529;
  margin: 0 5px;
  width: 50px;
  height: 50px;
  font-size: 24px;
  text-align: center;
}

.react-code-input input:focus, .react-code-input input:focus-visible{
    box-shadow: 0 3px 6px 0 rgb(11 181 166 / 10%);
    border-color: #0BB5A6;
    
}


.square-icon{
  border-radius: 8px;
  background-color: #F5F6F8;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px
}
img{
  max-width: 100%;
}
.square-icon-sm{
  height: 40px;
  width: 40px;
}
.rounded{
  border-radius: 1rem!important;
}
.box{
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 3px 6px #00000029;
  
}

.mnw6qvm {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x:hidden
}

.stretch{
  overflow-y: auto;
  min-height: 100%;
}

.transparent-box{
  border-radius: 8px;
  box-shadow: 0 3px 6px #00000029;
  overflow: hidden;
}

.box.box-error{
  box-shadow: 0 0 6px var(--danger)
}

/** WISYWIG **/
.draft-editor-container .DraftEditor-root{
  min-height: 160px;
  max-height: 80vh;
  padding: 10px;
  flex-grow: 1;
  cursor: text;
  overflow-y: scroll;
  margin-bottom: 10px;
}
.rdw-block-image-wrapper .rdw-popup-wrapper{
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 30px;
}
.rdw-block-image-wrapper:hover{
  box-shadow: 1px 1px 0px #bfbdbd;
    background-color: #FFFFFF;
    cursor: pointer
}
.task-chat-editor .draft-editor-container .DraftEditor-root{
  min-height: 80px;
  max-height: 80px;
  overflow-y: auto;
}
.rdw-editor-toolbar{
  padding: 0!important;
  margin: 0!important;
  align-items: center;
}
.rdw-editor-toolbar > * {
  margin-bottom: 0!important;
}
.DraftEditor-editorContainer{
  height: auto!important;
}
.rdw-inline-wrapper,
.rdw-block-wrapper{
  border-right: 1px solid #C7C7C7!important;
}
.rdw-option-wrapper{
  border: none!important;
  padding: 18px 15px!important;
  margin: 0 4px 0 0!important;
}
.rdw-dropdown-wrapper{
  border: none!important;
}
.public-DraftStyleDefault-block{
  margin: 0!important;
}
.draft-editor-container:global(.public-DraftEditor-content) {
  min-height: 140px;
}
.draft-editor-container img{
  max-width: 100%;
}
.draft-editor-container{
  background: #fff;
  border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.draft-editor-buttons{
  border-top: 1px solid #ced4da;
  display: flex

}
.RichEditor-controls{
  padding: 8px 10px;
  border-right: 1px solid #ced4da
}
.draft-editor-buttons .RichEditor-controls:last-of-type{
  border-right: 0
}
.draft-editor-button{
  cursor: pointer;
  margin-right: 5px;
}
.draft-editor-buttons select{
  border: 0!important;
}

.draft-editor-buttons-section{
  display: flex;
}
.draft-editor-buttons-section > *{
  padding: 7px 15px 4px 15px;
  border-right: 1px solid #ced4da
}
.RichEditor-styleButton{
  padding: 2px 7px;
  border-radius: 5px;
  cursor: pointer;
}
.RichEditor-activeButton{
  background-color: #ced4da;
}
.style-button-active{
  background-color: #ced4da;
}

/** NOTES **/
.note-item{
  border-radius: .8rem;
  transition: background 250ms ease;
}
.note-item:hover{
  background-color: #EDF4F3;
  display: block;
}
.note-item p{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



/** TASK CALENDAR **/
.day-item{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 0 0 14.28571428571429%;
  max-width: 14.28571428571429%;
  
}
.day-item-container{
  text-align: center;
  border-radius: 8px;
  padding:1rem;
  cursor: pointer;
  transition: background 100ms ease;
}
.day-item-active{
  background-color:#016450;
}
.day-item-active p{
  color:#FFF;
}

.tasks > div:last-of-type{
  border-bottom: 0!important
}

.hide-carret.dropdown-toggle:after{
  display:none
}
.nav-pills .nav-link:first-of-type{
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}
.nav-pills .nav-link{
    text-align: center;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
	border-radius: 0;
	background-color: #F5F6F8;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 60px;
	width: 60px
}
.nav-pills .nav-link:last-of-type{
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.nav-pills .nav-link.active{
	background-color: #00d0bd;
}
.form-control{
  border-width: 2px;
  background: #FFF;
  padding: .75rem .75rem
}
.form-control:focus{
  box-shadow: 0 3px 6px 0 rgba(11, 181, 166,.1);
  border-color: #0BB5A6
}
.ProseMirror{
  background-color: #FFF;
  padding: .5rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.75rem;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
}

/** BACKGROUNDS **/
.bg-ultra-light{
  background-color: #FCFDFE;
}
.bg-success, .bg-primary{
  background-color: var(--success)!important;
}
.bg-success-tint{
  background-color: var(--success-tint);
}
.bg-warning-tint{
  background-color: var(--warning-tint);
}
.bg-danger-tint{
  background-color: var(--danger-tint);
}
ul.side-step{
  list-style: none;
  padding: 0;
}
ul.side-step li{
  background: #f9f8f8;
  color: #868686;
  font-size: 1.2rem;
  font-weight: 600;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f9f8f8;
  margin-bottom: 50px;
  position: relative;
}
ul.side-step li:not(:last-of-type):after{
  content:"";
  background: #eae5e5;
  height: 50px;
  width: 2px;
  position: absolute;
  top: calc(100% + 2px);
}
ul.side-step li.active{
  background: #eae5e5;
  color:#000;
  border-color: #868686
}
ul.side-step li.completed{
  background: #868686;
  color:#FFF;
  border-color: #868686
}
.mega-btn{
  transition: .15s ease-in;
  border: 2px solid transparent;
}
.mega-btn h5{
  transition: .2s ease-in;
}
.mega-btn:hover{
  border: 2px solid #0BB5A6;
  box-shadow: none;
}
.mega-btn:hover h5{
  color: #0BB5A6!important;
}
.btn-black{
  background: #000;
  color:#FFF;
}
.btn-black-outline{
  background: transparent;
  color:#000;
  border-color: #000;
}

.form-group label{
  color:#868686;
  font-size: .9rem;
  margin:0;
  font-weight: 600;
  margin-bottom: 5px
}

/** FILE UPLOAD **/
.file-upload-container{
  width: 100%;
  border: 2px dashed var(--green);
  background-color: var(--green-light);
  padding: 2rem 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  transition: .15s ease-in-out;
}
.file-upload-container:hover, .file-upload-container.drag-active{
  box-shadow: inset 0 0 20px rgba(0,0,0,.4);
}
.file-upload-container span{
  color: var(--green-dark);
}
.file-upload-container.highlight{
  border-color: var(--green);
}
.file-wrapper{
  border: 1px solid #cecece;
  border-radius: 4px;
}
.file-item{
  padding: 10px;
  border-bottom: 1px solid #cecece;
}
.file-item:last-of-type{
  border: 0
}
.file-item h6 {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** ICON CHECK **/
.icon-check-container{
  position: relative;
}


.icon-check-button{
  height: 40px;
  width: 40px;
  background-color: transparent;
  border: 1px dashed #ced4da;;
  border-radius: 50%;
  position: relative;
  transition:  150ms ease
}
.icon-check-button:hover{
  border: 1px dashed #000;
}
.icon-check-button:hover > *{
  color: #000;
}
.icon-check-button > *{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ced4da;
}

.icon-check-container.checked .icon-check-button > *{
  color:#FFF;
}
.icon-check-container.checked.checked-success .icon-check-button{
  background-color: var(--success);
  border-color: var(--success)
}
.icon-check-container.checked.checked-warning .icon-check-button{
  background-color: var(--warning);
  border-color: var(--warning)
}
.icon-check-container.checked.checked-danger .icon-check-button{
  background-color: var(--danger);
  border-color: var(--danger)
}


.loading-overlay{
  position: relative;
}

.loading-overlay:after{
  content:"";
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,.1);
  z-index: 9;
}

.loading-overlay-icon{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  color: var(--warning)
}

/** PERSON PICKER **/
.person-picker-container{
  position: relative;
  
}
.person-picker-button{
  height: 40px;
  width: 40px;
  background-color: transparent;
  border: 1px dashed #ced4da;;
  border-radius: 50%;
  position: relative;
}
.person-picker-button > *{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ced4da;
}
.person-picker-dropdown{
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #FFF;
  z-index: 99;
  min-width: 300px;
}
.person-picker-item{
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 250ms ease;
}
.person-picker-item:hover{
  background-color: var(--light-grey);
}
.person-picker-search{
  padding: 5px 10px;
  border-bottom: 1px solid #ced4da;
}
.person-picker-wrapper{
  max-height: 240px;
  overflow-y: scroll;
}

.person-picker-wrapper::-webkit-scrollbar-track{
	background-color: #FFF;
}

.person-picker-wrapper::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
  position: absolute;
}

.person-picker-wrapper::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: var(--grey);
}
.person-picker-dropdown-wrapper-enter {
  opacity: 0;
}
.person-picker-dropdown-wrapper-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.person-picker-dropdown-wrapper-exit {
  opacity: 1;
}
.person-picker-dropdown-wrapper-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/** PERSON MODAL **/

.person-modal-wrapper{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;

}
.person-modal-container{
  position: relative;
  display: block;
  width: 1024px;
  max-width: 90vw;
  margin: 0 auto;
  height: 90vh;
  max-height: 90vh;
  z-index: 9999;
}
.person-modal-content-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.person-modal-person-wrapper{
  padding: 0;
  flex: 1 0;
  overflow-y: scroll;
  /*border: 1px solid var(--light-grey);
  box-shadow: 0 0 20px var(--light-grey) inset;*/
}
.person-modal-person-wrapper::-webkit-scrollbar-track{
	background-color: #FFF;
}

.person-modal-person-wrapper::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
  position: absolute;
}
.person-modal-person-wrapper::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: var(--grey);
}
.person-modal-header{

}
.person-modal-footer{
  border-top: 1px solid #cecece
}
.person-modal-person-item{
  border-bottom: 1px solid #cecece;
  transition: background-color 150ms ease;
}
.person-modal-person-item:hover{
  background-color: var(--success-tint);
  border-color: transparent;
}
.person-modal-person-item:last-of-type{
  border-bottom: 0
}
.person-modal-person-item-icon{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--success);
  color:#FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-modal-enter  {
  opacity: 0;
}
.person-modal-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.person-modal-exit {
  opacity: 1;
}
.person-modal-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.person-modal-enter .person-modal-container{
  transform: scale(.75);
}
.person-modal-enter-active .person-modal-container{
  transform: scale(1);
  transition: all 200ms;
}
.person-modal-exit .person-modal-container{
  transform: scale(1);
}
.person-modal-exit-active .person-modal-container{
  transform: scale(.75);
  transition: all 200ms;
}

/** CHECKBOX **/
.checkbox [type="checkbox"]{
  width:0;
  height: 0;
}
.checkbox{
  align-items: center;
}
.checkbox label > span{
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--grey);
  margin-right: 10px;
  vertical-align:middle;
  border-radius: 3px;
  position: relative;
  transition: border 250ms ease;
}
.checkbox label span span{
  position: absolute;
  background-color: var(--success);
  width: 0%;
  height: 0%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 100ms ease;
}
.checkbox label span span > *{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  color: #FFF;
}
.checkbox [type="checkbox"]:checked + label > span{
  border-color: var(--success)
}
.checkbox [type="checkbox"]:checked + label > span > span{
  width:100%;
  height: 100%;
}

/** RADIO GROUP **/
.radio-group{
  display: flex;
  flex-wrap: wrap;
}
.radio-button [type="radio"]{
  width:0;
  height: 0;
}
.radio-button{
  display: flex;
  align-items: center;
  margin: 0 5px 5px 0
}

.radio-button label{
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 4px 10px;
  transition: all 150ms ease;
  cursor: pointer;
}
.radio-group.radio-large .radio-button label{
  padding: 10px 15px;
}
.radio-button label > span{
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid var(--grey);
  margin-right: 10px;
  vertical-align:middle;
  border-radius: 50%;
  position: relative;
  transition: border 250ms ease;
  
  background-color: #FFF;
}
.radio-button label span span{
  position: absolute;
  width: 0%;
  height: 0%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 100ms ease;
}
.radio-button label span span > *{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  color: var(--primary);
  opacity: 0;
}
.radio-button [type="radio"]:checked + label{
  border-color: var(--green);
  background-color: var(--green);
  color: #FFF!important;
}
.radio-button [type="radio"]:checked + label > span{
  border-color: var(--green)
}
.radio-button [type="radio"]:checked + label > span > span > *{
  opacity: 1;
}

/** DATEPICKER **/
.date-picker-container, .flatpickr-input{
  border: 1px solid #70707063;
  border-radius: 4px;
  padding: .75rem .75rem;
  cursor: pointer;
  width: 100%;
}
.date-picker-placeholder{
  color: var(--grey);
}
.react-datepicker__header{
  background-color: #FFF;
  border: 0;
}
.react-datepicker__day-name{
  color: var(--primary);
}

.react-datepicker__day--in-selecting-range{
  background-color: var(--green-tint)!important;
  border-radius: 0;
  color:#000;
}

.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end{
  background-color: var(--green)!important;
  border-radius: 0;
  color:#000;
}
.react-datepicker__day--selecting-range-start{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.react-datepicker__day--in-selecting-range:nth-last-child(){
  background-color: var(--green)!important;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.react-datepicker__day--in-range{
  background-color: var(--green-tint);
  border-radius: 0;
  color:#000;
}
.react-datepicker__day--range-end{
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.react-datepicker__day--range-start{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.react-datepicker__day--selected, .react-datepicker__day--range-end{
  background-color: var(--primary);
  color:#FFF
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2rem;
  margin: 0.166rem 0;
}

/** BUTTONS **/
.btn-primary{
  background-color: var(--green);
  border-color: var(--green);
}
.btn-primary:hover{
  background-color: var(--green-tint);
  border-color: var(--green-tint);
  color: #000;
}
.btn-border{
  border-color: rgba(0,0,0,.25);
  box-shadow: 1px 1px 0 1px rgba(0,0,0,0.05);
  border-width: 1px;
}
.btn-border:hover{
  border-color: rgba(0,0,0,.05);
  box-shadow: none;
  color: var(--grey);
}
/** DROPDOWN **/
.dropdown{
	display: flex;
  align-items: center;
	justify-content: space-between;
	padding: .75rem .75rem;
	cursor: pointer;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  background: #FFF;
}
.dropdown-container.disabled .dropdown{
  background-color: var(--light-grey);
}
.dropdown.focus{
	border-bottom-width: 0;
}
.dropdown.error, .form-group.error .dropdown{
	border-color: var(--danger);
}

.image-crop-container.error .create-logo-wrapper{
	border-color: var(--danger);
}
.image-crop-container.valid .create-logo-wrapper, .dropdown-container:focus .dropdown{
  border-color: var(--success);
}

.dropdown.valid, .form-group.valid .dropdown{
	border-color: var(--success);
}
.dropdown p{
	margin: 0;
}
.dropdown-options{
	border: 1px solid #ced4da;
	border-top: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
	position: absolute;
	width: 100%;
	top:calc(100%);
  background-color: #f8f9fb;
  z-index: 99;
}

.dropdown-options ul{
	list-style: none;
	margin:0;
	padding: 0;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-options ul::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
.dropdown-options ul::-webkit-scrollbar-track {
	background: rgba(0,0,0,.1);
}

/* Handle */
.dropdown-options ul::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
}

/* Handle on hover */
.dropdown-options ul::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,.1);
}
.dropdown-options li{
	padding: 10px 10px;
	cursor: pointer;
	transition: background-color 250ms ease;
}
.dropdown-options li:hover{
	background: var(--light-grey);
}
.dropdown-options li.active{
	background: var(--green);
	color: white
}
.dropdown-enter {
	opacity: 0;
	transform: translateY(-50px)
}
.dropdown-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: all 200ms;
}
.dropdown-exit {
	opacity: 1;
	transform: translateY(0);
}
.dropdown-exit-active {
	opacity: 0;
	transform: translateY(-50px);
	transition: all 200ms;
}

/** NOTIFICATION **/
.notification{
 display: flex;
 flex-wrap: wrap;
 position: fixed;
 top: 30px;
 right: 30px;
 background-color: #FFF;
 width: 360px;
 height: 120px;
 border-radius: 4px;
 box-shadow: 0 0 10px rgba(0,0,0,.15);
 z-index: 999;
}
.notification-icon{
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.notification.notification-success .notification-icon{
  background-color: var(--success);
}
.notification.notification-info .notification-icon{
  background-color: var(--info);
}
.notification.notification-warning .notification-icon{
  background-color: var(--warning);
}
.notification.notification-danger .notification-icon{
  background-color: var(--danger);
}
.notification-icon > *{
  color: #FFF
}
.notification-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  flex: 1 0;
}

.notification.notification-success .notification-content h5{
  color: var(--success)
}
.notification.notification-info .notification-content h5{
  color: var(--info)
}
.notification.notification-warning .notification-content h5{
  color: var(--warning)
}
.notification.notification-danger .notification-content h5{
  color: var(--danger)
}

.notification-enter {
  transform: translateX(500px);
  opacity: 0;
}
.notification-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}
.notification-exit {
  transform: translateX(0);
  opacity: 1;
}
.notification-exit-active {
  transform: translateX(500px);
  opacity: 0;
  transition: all 200ms;
}

/** FORM COMPONENTS **/
/* CHECK */

.faux-placeholder svg{
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(calc(-50% - 1px)); /* Optical nitpicking */
}
.faux-placeholder input{
  padding-left: 2.5rem;
}
.radio-group{
  display: flex;
  flex-wrap: wrap;
}
.custom-form-check [type="radio"]{
  width:0;
  height: 0;
}
.custom-form-check{
  display: flex;
  align-items: center;
  margin: 0 5px 5px 0
}

.custom-form-check label{
  border-radius: 3px;
  padding: 4px 10px;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid var(--success);
}

.custom-form-check.success label{
  border-color: var(--success);
  color: var(--success)
}
.custom-form-check.warning label{
  border-color: var(--warning);
  color: var(--warning)
}
.custom-form-check.danger label{
  border-color: var(--danger);
  color: var(--danger)
}

.custom-form-check [type="radio"]:checked + label{
  color: #FFF!important;
}
.custom-form-check.success [type="radio"]:checked + label{
  border-color: var(--success);
  background-color: var(--success);
}
.custom-form-check.warning [type="radio"]:checked + label{
  border-color: var(--warning);
  background-color: var(--warning);
}
.custom-form-check.danger [type="radio"]:checked + label{
  border-color: var(--danger);
  background-color: var(--danger);
}


.form-group.valid .form-control{
  border-color: var(--success)
}

.form-group.error label{
  color: var(--danger)!important;
}
.form-group.error .form-control{
  border-color: var(--danger)
}
/** CHANNELS **/
.channel-sidebar{
  border-right: 1px solid var(--light-grey);
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 30%;
}
.channel-tabs span:not(.active):hover{
  color: var(--primary);
}
@media (max-width: 767px){
  .channel-sidebar{
    min-width: 0;
    width: 0;
  }
  .channel-sidebar-closed{
    display:none
  }
  .channel-sidebar.channel-sidebar-open, .channel-sidebar-open{
    width: 100%;
  }
  .channel-content-closed{
    display:none
  }
}
.channel-header{
  border-bottom: 1px solid var(--light-grey);
  text-transform: uppercase;
  
}
.channel-header h6{
  font-weight: 600!important;
}
.channel-sidebar-items{
  flex: 1 0
}
.channel-sidebar-item{
  cursor: pointer;
  transition: all 150ms ease;
}
.channel-sidebar-item > .row{
  padding: 1rem 1rem;
}
a.channel-sidebar-item{
  padding: 1rem;
  justify-content: space-between;
}
.channel-sidebar-item.active{
  background-color: var(--green-tint);
  font-weight: bold;
}
.image-stack{
  position: relative;
  display: flex;
  justify-items: end;
  align-self: end;
}
.image-stack img, .image-stack > div{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border:1px solid #FFF;
}
.image-stack > *:nth-child(1){z-index: 10;}
.image-stack > *:nth-child(2){transform:translateX(-20px);z-index: 9;}
.image-stack > *:nth-child(3){transform:translateX(-40px);z-index: 8;}
.image-stack > *:nth-child(4){transform:translateX(-60px);z-index: 7;}
.image-stack > *:nth-child(5){transform:translateX(-80px);z-index: 6;}
.image-stack > *:nth-child(6){transform:translateX(-100px);z-index: 5;}
.image-stack > *:nth-child(7){transform:translateX(-120px);z-index: 4;}
.image-stack > *:nth-child(8){transform:translateX(-140px);z-index: 3;}

.image-stack > *:hover{
  z-index: 20;
}

.channel-body{
  display:flex;
  flex-direction: column;
  height: 100%;
}
.channel-tabs{
  flex: 0 0 auto;
  border-bottom: 1px solid var(--light-grey)
}
.channel-tabs span{
  opacity: .8;
  cursor: pointer;
}
.channel-tabs span.active{
  opacity: 1;
  font-weight: 600;
}
.channel-conversation-messages{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
}

.message-tag{
  width: 100px;
  height: 75px;
  background: #FFF;
  border-radius: 5px;
  font-size: 30px
}
@media (max-width: 767px){
  .message-tag{
    width: 50px;
    height: 30px;
    background: #FFF;
    border-radius: 5px;
    font-size: 18px;
  }
}
.message-attachment-text{
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
/** RELOG **/
.relog-container{
  background-color: rgba(0,0,0,.25);
  backdrop-filter: blur(4px);
  position: fixed;
  z-index: 90;
  top:0;
  right:0;
  bottom:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.relog-enter {
  transform: scale(.8);
  opacity: 0;
}
.relog-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: all 200ms;
}
.relog-exit {
  transform: scale(1);
  opacity: 1;
}
.relog-exit-active {
  transform: scale(.8);
  opacity: 0;
  transition: all 200ms;
}

/** ELEMENTS **/

.pill{
  border-radius: 20px;
  border:1px solid;
}
.pill.pill-success{
  border-color: var(--success);
  color: var(--success);
  background-color: var(--success-tint);
}
.x-on-hover{
  opacity: 0;
  position: absolute;
  top:0;
  right:0;
  background-color: var(--danger);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  transition: opacity 150ms ease;
}
.position-relative:hover .x-on-hover{
  opacity: 1
}
.x-on-hover > *{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  font-size: 10px;
}
.profile-image-replacement{
  min-width: 30px;
  min-height: 30px;
  background-color: var(--success-tint);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:var(--success);
  pointer-events: none;
}
.profile-image-replacement > span{

}

.border-icon{
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color:#D9F4F2;
  border: 5px solid #EBF9F8;
  color: var(--success)
}

.bordered-color{
  border: 1px solid;
  border-radius: 5px;
  display: inline-block;
}
.bordered-color.success{
  border-color: var(--success);
  background-color: var(--success-tint);
  color: var(--success)
}
.bordered-color.warning{
  border-color: var(--warning);
  background-color: var(--warning-tint);
  color: var(--warning)
}
.bordered-color.danger{
  border-color: var(--danger);
  background-color: var(--danger-tint);
  color: var(--danger)
}


.btn-square-icon{
  width: 40px;
  height: 40px;
  border: 0;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-square-icon:hover{
  background-color: var(--light-grey);
}

.btn-up{
  border: 0;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  padding: 1rem 1.5rem;
}
.btn-up span{
  opacity: .7;
}
.btn-up:hover{
  background-color: var(--light-grey);
}
/** CONTEXT MENU **/
.context-menu-wrapper{
  position: relative;
  
}
.context-menu-container{
  position: absolute;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 3px 6px #00000029;
  right: 100%;
  top:0;
  z-index: 99;
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  margin: 0 auto;
}
.context-menu-container ul{
  list-style: none;
  padding: 0;
  margin: 0;
  width: 200px;
  max-width: 100%;
}

.context-menu-container ul li{
  padding: 10px 20px;
  cursor: pointer;
  transition: all 100ms ease;
}
.context-menu-container ul li:hover{
  background-color: var(--success-tint);
}
.context-menu-container ul li.text-danger:hover{
  background-color: var(--danger-tint);
}

/** TOOLTIP **/
.tooltip-container{
  position: relative;
}
.tooltipp{
  
  padding: 8px 15px;
  background-color: #585858;
  color:#FFF;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 999;
  pointer-events: none;
}
.tooltipp.tooltipp-bottom{
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
}
.tooltipp.tooltipp-top{
  bottom: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
}
.tooltipp.tooltipp-right{
  left: calc(100% + 15px);
  top: 50%;
  transform: translateY(-50%);
}
.tooltipp.tooltipp-left{
  right: calc(100% + 15px);
  top: 50%;
  transform: translateY(-50%);
}
.tooltipp:after{
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
}
.tooltipp.tooltipp-bottom:after{
  bottom: 100%;
  left: 50%;
  transform:translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--darker-grey);
}
.tooltipp.tooltipp-top:after{
  top: 100%;
  left: 50%;
  transform:translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid var(--darker-grey);
}
.tooltipp.tooltipp-right:after{
  right: 100%;
  top: 50%;
  transform:translateY(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid var(--darker-grey);
  border-top: 10px solid transparent;
  border-bottom: 15px solid transparent;
}
.tooltipp.tooltipp-left:after{
  left: 100%;
  top: 50%;
  transform:translateY(-50%);
  border-left: 15px solid var(--darker-grey);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.tooltip-appear{
  opacity: 0;
}
.tooltip-enter {
  opacity: 0;
}
.tooltip-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.tooltip-exit {
  opacity: 1;
}
.tooltip-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/** DRAWER **/
.drawer-wrapper-enter  {
  opacity: 0;
}
.drawer-wrapper-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.drawer-wrapper-exit {
  opacity: 1;
}
.drawer-wrapper-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.drawer-wrapper{
  position: fixed;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,.1);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.drawer-container{
  background-color: #FFF;
  height: 95vh;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 10px 10px 10px #000;
  padding: 1rem;
  overflow-y: auto ;
  transition: all 100ms;
}
.drawer-container .container{
  height: 100%;
}
.drawer-close-button{
  position:absolute;
  top: 0;
  right:0;
  padding: 20px;
}
.drawer-container-enter  {
  transform: translateY(100%)
}
.drawer-container-enter-active {
  transform: translateY(0);
  transition: all 250ms;
}
.drawer-container-exit {
  transform: translateY(0);
}
.drawer-container-exit-active {
  transform: translateY(100%);
  transition: all 250ms;
}



.modal-wrapper{
  position: fixed;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,.1);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-container{
  background-color: #FFF;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,.25);
  padding: 1rem;
  overflow-y: auto ;
  transition: all 100ms;
  overflow: visible;
  max-height: 90vh;
  overflow-y: auto;
  max-width: 90vw;

}
.modal-container .container{
  height: 100%;
}
.modal-close-button{
  position:absolute;
  top: 0;
  right:0;
  padding: 20px;
}
.modal-container-enter  {
  transform: translateY(300px);
  opacity: 0;
}
.modal-container-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 250ms;
}
.modal-container-exit {
  transform: translateY(0);
  opacity: 1;
}
.modal-container-exit-active {
  transform: translateY(-300px);
  opacity: 0;
  transition: all 250ms;
}

/** FORM BUILDER **/
.form-category-header{
  border-bottom: 1px solid #cecece
}

/** TABLE **/
.table-grid{
  border: 1px solid #cecece;
  border-radius: 5px;
}
.table-header{
  display: grid;
  padding: .5rem 1rem;
  background-color: rgba(0,0,0,.05);
  border-bottom: 1px solid #cecece
}
.table-header span{
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 600;
  color: #00000099
}
.table-row{
  display: grid;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #cecece;
  transition: background-color 250ms ease;
}
@media (max-width: 767px) {
  .table-row{
    padding: .5rem
  }
}
.table-row:first-of-type{
  border-top: 0;
}
.table-row:hover{
  background-color: var(--success-tint);
}



/** BORDERS **/
.border-success{
  border-color: var(--success)
}
.border-right{
  border-right: 1px solid var(--light-grey)
}
.border-left{
  border-left: 1px solid var(--light-grey)
}
.border-bottom{
  border: 0;
  border-bottom: 1px solid var(--light-grey)
}

.border-dashed{
  border-style: dashed!important;
}

/** SWITCH **/
.switch {
  position: relative;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {

  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: var(--light-grey);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  position: relative;
}

.slider-item{
  flex: 0 0 auto;
  padding: 10px 25px;
  z-index: 2;
  transition: color 250ms ease;
}
.slider-item.active{
  color: #FFF
}

.slider-block{
  position: absolute;
  background-color: var(--success);
  height: calc(100% - 2px);
  top: 1px;
  border-radius: 34px;
  opacity: .9;
  pointer-events: none;
  transition: all 250ms ease
}

.slider:before {
  position: absolute;
  content: "";
  height: 100%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* PAGINATION */
.pagination{
  display: flex;
  border: 1px solid #cecece;
  border-radius: 5px;
}
.pagination span.disabled{
  opacity: .8;
  cursor: initial;
}
.pagination span:first-of-type{
  border-right: 1px solid #cecece
}
.pagination span:last-of-type{
  border-left: 1px solid #cecece
}
.pagination span{
  padding: 5px 10px 3px 10px;
  cursor: pointer;
}
.pagination span.active{
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
}

.bottom-border{
  border-bottom: 1px solid #cecece;
}
.bottom-border:last-of-type{
  border-bottom: 0
}


/** RANGE **/
.range-container {
  width: 100%; /* Width of the outside container */
  position: relative;
  
}

/* The slider itself */
.range {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  border-radius: 10px
}

/* Mouse-over effects */
.range:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.range::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 40px; /* Set a specific slider handle width */
  height: 40px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 20px;
}

.range::-moz-range-thumb {
  width: 50px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.range-value{
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  color:#FFF;
  font-weight: 600;
}
.task-chat-wrapper{
  min-height: 300px;
  max-height: 35vh;
}
.task-chat-log{
  overflow-y: auto
}
/* width */
.task-chat-log::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
.task-chat-log::-webkit-scrollbar-track {
	background: rgba(0,0,0,.1);
}

/* Handle */
.task-chat-log::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
}

/* Handle on hover */
.task-chat-log::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,.1);
}

/** NOTIFICATION CENTER **/
.notification-center-wrapper{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.1);
  display: flex;
  justify-content: end;
  z-index: 100;
}
.notification-center-container{
  background-color: #FFF;
}
.notification-center-wrapper-enter  {

  opacity: 0;
}
.notification-center-wrapper-enter-active {

  opacity: 1;
  transition: all 250ms;
}
.notification-center-wrapper-exit {

  opacity: 1;
}
.notification-center-wrapper-exit-active {

  opacity: 0;
  transition: all 250ms;
}
.notification-center-container-enter  {
  transform: translateX(100%);
  opacity: 0;
}
.notification-center-container-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 250ms;
}
.notification-center-container-exit {
  transform: translateX(0);
  opacity: 1;
}
.notification-center-container-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: all 250ms;
}

.slide-enter {
  transform: translateY(-100%);
  transition: .3s cubic-bezier(0, 1, 0.5, 1);

  
}
.slide-enter-active {
  transform: translateY(0%);
}
.slide-leave {
  transform: translateY(0%);
  transition: .3s ease-in-out;

  
}
.slide-leave-active {
  transform: translateY(-100%);
}

/** SIGNATURES **/
.signature-item{
  display: flex;
  border: 1px solid;
  border-radius: 30px;
  padding: 3px 3px 3px 3px
}

.signature-item-signed{
  background-color: var(--success-tint);
  border-color: var(--success-tint);
}
.signature-item-not-signed{
  background-color: var(--danger-tint);
  border-color: var(--danger-tint);
}
.signature-photo{
  margin-right: 10px
}
.signature-content{
  align-self: center;
}
.signature-button{
  padding-left: 20px
}

.signature-button button{
  border: 0;
  height: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: var(--primary);
  color: var(--bs-white);
  transition: all 250ms ease;
  padding: 10px 20px
}
.signature-button button:hover{
  background-color: var(--green-dark);
}

/** PROFILE **/
.profile-menu{
  position:fixed;
  top:10px;
  right:20px;
  z-index: 999;
}
.profile-menu-list-item{
  padding: 10px 15px;
  display: flex;
  align-items: center;
  
}
.profile-menu-list-icon{
  margin-right: 20px
}

/** COMPANY PICKER **/

.company-picker{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
}
@media (max-width: 767px){
  .company-picker{
    
  }
}
.company-picker-container{
  border-radius: 5px;
  min-width: 275px;
  height: calc(100% - 20px);
  padding: 5px 10px;
  display: flex;
}
@media (max-width: 767px){
  .company-picker-container{
    min-width: 0;
    height: auto;
  }
}
.company-picker-container:hover,
.company-picker-container.picker-open{
  background-color: var(--light-grey);
}
.company-picker-dropdown{
  position: absolute;
  top: calc(100% - 10px);
  left: 10px;
  z-index: 999;
  width: 600px;
  max-width: 100%;
}
@media (max-width: 767px){
  .company-picker-dropdown{
    width: calc(100vw - 10px);
    max-width: 100vw;
    left: 5px;
    box-shadow: 0 0 50px rgba(0,0,0,.05);
  }
}
.pre-company-choice .company-picker-container{
  width: 75%;
  margin: 0 auto 1.5rem;
}
.pre-company-choice .company-picker-container .row{
  width: 100%;
}
.pre-company-choice .company-picker-dropdown{
  width: 75%;
  transform: translateX(-50%);
  left: 50%;
}
.subtask-item-disabled span{
  opacity: .5;
  text-decoration: line-through;
}
.taskname{
  font-size: 1.15rem;
}
/** CROPPING **/
.image-crop-wrapper{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.create-logo-wrapper{
  
  overflow: hidden;
  border: 2px dashed var(--grey);
  position: relative;
  cursor: pointer;
}
.create-logo-wrapper.dragging{
  border-color: var(--success);
}
.create-logo-wrapper span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  color: var(--grey);
  font-size: 20px;
}
.create-image-wrapper{
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px dashed var(--grey);
  position: relative;
  cursor: pointer;
}
.create-image-wrapper span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  color: var(--grey);
  font-size: 16px;
}


.submission-drawer-dot{
  padding-top: 5px;
  height: 100%;
  position: relative;
}
.submission-drawer-dot > .dot{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--primary);
}
.submission-drawer-item:not(:last-child) .submission-drawer-dot:after{
  content:"";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width:2px;
  height: calc(100% - 30px);
  top: 25px;
  background-color: var(--light-grey);
}
.overflow-y-auto{
  overflow-y: auto;
}
.scrollinside{
  overflow: hidden;
  overflow-y: auto;
}
/* width */
.scrollinside::-webkit-scrollbar {
  width: 5px;
  overflow: hidden;
}

/* Track */
.scrollinside::-webkit-scrollbar-track {
	background: rgba(0,0,0,.1);
}

/* Handle */
.scrollinside::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
}

/* Handle on hover */
.scrollinside::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,.1);
}

/* MORTEN TESTER TEITE TING - SCROLL I RUTE*/
@media(min-width: 992px){
  /*.main{
    height: 100vh;
    max-height: 100vh;
  }
  .scrollinside{
    overflow: scroll;
  }*/
  .bg-login{
    background: transparent linear-gradient(137deg, #5DEDE0 0%, #3BB9AE 29%, #1AA094 86%, #1B8D83 100%) 0% 0% no-repeat padding-box;
  }
  .big-text{
    background-color: transparent;
    padding: .75rem 1rem;
  }
  .border.pointer{
    transition: .15s;
  }
  .border.pointer:hover{
    border-color: var(--green)!important;
    background: rgba(11,181,166,.15);
  }
}

@media(min-width: 1200px){
  .react-grid-layout.layout{
    margin: 0 1rem;
  }
}

@media(min-width: 1400px){
  
}

.context-picker-row > div:not(:nth-of-type(1)){
  border-left: 2px solid var(--light-grey)
}

.tab-container{
  border-bottom: 1px solid var(--light-grey)
}
.tab-item{
  padding: 10px 40px;
  border-right: 1px solid var(--light-grey);
  position: relative;
  display: inline-block;
}
@media (max-width: 767px){
  .tab-item{
    padding: 5px 10px;
    font-size: 14px;
  }
}
.tab-item:first-child{
  border-left: 1px solid var(--light-grey);
}
.tab-item.tab-item-active{
  background-color: #FFF;
  color: var(--success);
  bottom: -1px;
}

.fullscreenloader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #0bb5a6;
  background: -moz-linear-gradient(left, #0bb5a6 10%, rgba(11,181,166, 0) 42%);
  background: -webkit-linear-gradient(left, #0bb5a6 10%, rgba(11,181,166, 0) 42%);
  background: -o-linear-gradient(left, #0bb5a6 10%, rgba(11,181,166, 0) 42%);
  background: -ms-linear-gradient(left, #0bb5a6 10%, rgba(11,181,166, 0) 42%);
  background: linear-gradient(to right, #0bb5a6 10%, rgba(11,181,166, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.fullscreenloader:before {
  width: 50%;
  height: 50%;
  background: #0bb5a6;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.fullscreenloader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.border-bottom-not-last{
  border-bottom: 1px solid var(--light-grey)
}
.border-bottom-not-last:nth-last-of-type(){
  border-bottom: 0px
}

.consultant-photo{
  border: 2px solid var(--success)!important
}

.small-scroll::-webkit-scrollbar {
  width: 5px;
  overflow: hidden;
}

/* Track */
.small-scroll::-webkit-scrollbar-track {
	background: rgba(0,0,0,.1);
}

/* Handle */
.small-scroll::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
}

/* Handle on hover */
.small-scroll::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,.1);
}

.popper{
  z-index: 999;
}

#form-file-upload {
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}